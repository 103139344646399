<template>
	<div class="mainBox">
		<!--Header-->
		<div class="handleBox flex flex-jc-sb flex-ai-c">
			<el-page-header @back="goBack" :content="$route.query.title"></el-page-header>
			<el-button type="primary" size="mini" block @click="submit()">保存</el-button>
		</div>
		<div class="formBox activeBox">
			<el-row style="height: calc(100% - 70px);overflow: auto;">
				<el-form ref="formAuth" :rules="formRules" :model="formData" label-width="120px" label-position="top" size="small" class="alertForm">
					<el-form-item label="活动名称" prop="name" style="width: 100%;">
						<el-input v-model="formData.name" placeholder="活动名称" autocomplete="off"></el-input>
					</el-form-item>
					<el-form-item label="活动详情" prop="content" class="shopDes" style="width:100%;margin-right: 1%;">
						<editorBar :editorMain="formData.content" @change="editorChange"></editorBar>
					</el-form-item>
				</el-form>
			</el-row>
		</div>
	</div>
</template>

<script>
	import editorBar from '@/components/editorBar';
	import { activeSaveApi,activeReadApi } from '@/api/config.js';
	export default {
		data() {
			return {
				title: '',
				formData: {},
				formRules: {
					name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'change'
					}],
					content: [{
						required: true,
						message: '请输入活动详情',
						trigger: 'change'
					}],
				}
			}
		},
		components: {
			editorBar, //富文本编辑器
		},
		mounted() {
			this.read()
		},
		methods: {
			read: function(){
				if(!this.$route.query.id){
					return;
				}
				activeReadApi({
					id:this.$route.query.id
				}).then(response=>{
					this.formData = response;
				})
			},
			editorChange: function(e) {
				this.formData.content = e;
			},
			goBack: function() {
				this.$router.go(-1)
			},
			submit: function() {
				this.$refs['formAuth'].validate((valid) => {
					if (valid) {
						activeSaveApi(this.formData).then(() => {
							this.$message({
								type: 'success',
								message: '保存成功!'
							});
							this.goBack();
						})
					}
				})
			}
		}
	}
</script>

<style lang="less">
	.activeBox {
		height: calc(100% - 60px);
		padding: 20px;
		box-sizing: border-Box;

		.shopDes {
			.editor {
				width: 100%;
				margin: 0;

				.w-e-toolbar .w-e-menu {
					padding: 5px 7px;
				}

				.w-e-text-container {
					min-height: 55vh !important;
				}
			}
		}
	}
</style>
